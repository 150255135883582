
<div class="table">
	<div class="table-header">
		@if (organizationList?.length) {
			<mat-form-field subscriptSizing="dynamic" appearance="fill"><mat-label>Select Organization</mat-label>
				<mat-select [(value)]="selectedOrganization" (selectionChange)="selectOrg($event?.value)">
					@for (org of organizationList; track org.id){
						<mat-option [value]="org" class="plan-option">
							<div class="plan-item">
								{{org.name}}
								<span class="P2Black500">
									ID: {{ org.id }}
								</span>
							</div>
							<div class="P2Black500">
								{{ hasActiveOrgPlan(org) ? "Active" : "Inactive"}}
								|
								{{ org.processing_credits + org.subscription_credits | comma }} credits
							</div>
						</mat-option>
					}
					<mat-select-trigger class="H7Black700Semibold">
						{{selectedOrganization.name}}
						<div class="P2Black500">
							{{ hasActiveOrgPlan(selectedOrganization) ? "Active" : "Inactive"}}
							|
							{{ selectedOrganization.processing_credits + selectedOrganization.subscription_credits | comma }} credits
						</div>
					</mat-select-trigger>
				</mat-select>
			</mat-form-field>
		}
	</div>
	@if (!isLoading) {
		<ng-container *ngTemplateOutlet="t_subscriptionSelection"></ng-container>
	}
	@if (isLoading) {
		<div class="loading-container">
			<div class="dot-stretching"></div>
		</div>
	}
</div>


<ng-template #t_subscriptionSelection>
	<div class="plans-container elevation-z2" *ngIf="productList?.length" [ngClass]="{'disabled': !userIsAdmin}">
		<mat-radio-group [(ngModel)]="selectedPlan">
			<ng-container *ngFor="let plan of productList">
				<div class="plan"
					 [ngClass]="{'active-plan elevation-z5': isSelectedPlan(plan)}">
					<div class="plan-header" (click)="setSelectedPlan(plan)">
						<mat-radio-button [value]="plan"></mat-radio-button>

						<div class="plan-block">
							<div class="plan-title">
								<div class="H5Black700 bold ellipsis">{{ plan.name }}</div>
								<!--								TODO: Reimplement or remove after discussion on future of plan purchasing -->
								<div *ngIf="isOrgPlan(plan, selectedOrganization)"
									 class="current-plan"
									 [ngClass]="getPlanStatusClass(selectedOrganization)">
									{{ getPlanStatusText(selectedOrganization) }}
								</div>
							</div>
							<p class="P2Black500">{{plan.description ?? 'No description.'}}</p>
						</div>

						<div class="price-block" *ngIf="plan.price">
							<div class="H5Black500">{{ ( plan.price / 100 ) | currency }}</div>
							<div class="P2Black500">{{ plan.meta_data?.plan_savings ? plan.meta_data.plan_savings :
								selectedPeriod.name }}</div>
						</div>
						<div class="price-block" *ngIf="!plan.price && plan.meta_data?.plan_price_1">
							<div class="H5Black500">{{ plan.meta_data.plan_price_1 }}</div>
							<div class="P2Black500">{{ plan.meta_data.plan_price_2 }}</div>
						</div>
					</div>

					@if ((selectedPlan === plan) ) {
						<div class="plan-footer">
							<div *ngIf="plan.meta_data?.length" class="plan-info">
								<div *ngFor="let feature of plan.meta_data" class="feature-block">
									<mat-icon class="">check</mat-icon>
									{{ feature }}
								</div>
							</div>
							<button mat-flat-button color="primary" (click)="selectPlan(plan);"
									*ngIf="showPlanSelect(plan, selectedOrganization)">
								<span>{{getPlanSelectText( plan, selectedOrganization )}}</span>
							</button>
						</div>
					}
				</div>
			</ng-container>
		</mat-radio-group>
	</div>
</ng-template>
