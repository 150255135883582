/* Imports */
import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Alert, ImageBatch, Model, Project} from '@shared/models';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {availableFeatureFlags, flagLayer} from '@shared/featureFlags';
import * as ExifReader from 'exifreader';

import _, {cloneDeep, isNil, merge} from 'lodash';
import moment from 'moment';
import {
	AlertService,
	AuthenticationService,
	byId,
	convertFilesByType,
	extractFileExtension,
	findUniqueName,
	ImageService,
	listFormatters, makeURLParams,
	ModelService,
	UploadItem,
	UploadItemsByType,
	UploadService,
	UploadType,
	uploadTypeEnum
} from '@shared/services';
import {MinimapPosition} from '@app/components/minimap';
import pluralize from 'pluralize';
import proj4 from '../../../assets/libs/proj4/proj4.js';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {sortByNewestToOldest} from '@app/pages/map/utils/map.utils';

// @ts-ignore - we do not have the latest ts check, so it's not aware of the new `listFormat`
const andFormatter = new Intl.ListFormat("en", {
	style: "long",
	type: "conjunction"
});

const batchNameValidator = (control: FormControl): ValidationErrors | null =>
	control.value.name?.length > 0 ? null : { nameMissing: true };

const defaultUploadItemsByType = { "Map Layer": [], "Image": [], "File": [] };

@Component({
	selector: 'app-upload-dialog',
	templateUrl: './upload.dialog.html',
	styleUrls: ['./upload.component.scss']
})
export class UploadDialog implements AfterViewInit {

	@ViewChild('fileInput') fileInput: ElementRef;
	@ViewChild('folderInput') folderInput: ElementRef;

	public batches: ImageBatch[];
	public project: Project;
	public showDropzoneWarning: boolean = false;
	public batchForm: FormGroup;
	public modelForm: FormGroup;
	public uploadItems: UploadItem[] = [];
	public uploadItemsByType: UploadItemsByType = defaultUploadItemsByType;
	public uploadItemsStrings: UploadType[] = ["Map Layer", "Image", "File"];
	public folderName: string = "";
	public showFiletypeError: boolean = false;
	public minimapValues: MinimapPosition[] = [];
	public batchOptions: Observable<any[]>;
	public modelOptions;
	public selectedModelOption;

	public tabShowing: UploadType = "Image";
	public isLoading: boolean = false;
	public multispectralImages: boolean = false;
	public imageGroupId: number;
	public multispectralSensor: string = "";
	public multispectralSensorsList: string[] = [];
	public hasUniqueName: boolean = false;
	public hasUniqueModelName: boolean = false;
	public isExistingGroup: boolean = true;
	public isExistingModel: boolean = true;
	public isDeveloper: boolean = true;
	public hasVectorFiles: boolean = true;

	public vectorOptions: Array<{header: string, text: string, type?: string}> = [
		{header: "Default", text: "Data will be treated normally."},
		{header: "Contour", text: "For contiguous contour lines.", type: "contour"},
		{header: "Inference Result", text: "For results from an AI Inference.", type: "inference"}
	];
	public selectedVectorOption: {header: string, text: string, type?: string} = this.vectorOptions[0];

	public uploadItemTooltips = {
		"Map Layer": "Map Layers can be shown Mapware’s 2D/3D Map Viewer, and your original file can be downloaded in the Project’s Map Layers tab.",
		"File": "Files uploaded here are kept for storage. Should you require them as either of the other types, please download and reupload."
	}

	public uploadTypes = [
		{
			type: uploadTypeEnum.import,
			text: "Import as",
			varText: (nItems) => nItems === 1 ? "a" : "",
			boldText: (nItems) => pluralize("Map Layer", nItems)
		},
		{
			type: uploadTypeEnum.image,
			text: "Upload to an",
			boldText: () => "Image Group"
		},
		{
			type: uploadTypeEnum.file,
			text: "Store in the Project's",
			boldText: () => "Files"
		}
	];

	constructor(
		public _alertService: AlertService,
		public _authService: AuthenticationService,
		public _modelService: ModelService,
		public _dialogRef: MatDialogRef<UploadDialog>,
		public _imageService: ImageService,
		private _formBuilder: FormBuilder,
		private _uploadService: UploadService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

		this.createFormGroup();

		this.isDeveloper = this._authService.isDeveloper;

		this._imageService.getMultispectralSensors().then(rtnSensors => this.multispectralSensorsList = rtnSensors);

		if (!isNil(data.project.id)) {
			this.project = data.project;
			this.imageGroupId = data.imageGroup?.id;

			this._imageService.getBatchListV2({ project_id: data.project.id }).then(rtnBatches => {
				this.updateBatches(rtnBatches);
			}).catch(console.error)

			this._modelService.getListV2(data.project).then((rtnList) => {
					this.modelOptions = rtnList.filter((model) => {
						return model.project_id === data.project.id && !model.trash && model.active;
					})
					this.updateModels();
				})
		}
	}

	public get batchesUpdate(): Array<any> {
		return this.batches;
	}

	async updateBatches(batches): Promise<any> {

		this.batches = batches;
		this.batchOptions = this.batchForm.controls['batchControl'].valueChanges.pipe(
			map((value) => {
				return (value?.length ? this.filterValues(value, this.batches) :
					this.batches
						.slice(0, 15)
						.sort(sortByNewestToOldest)
				);
			})
		);

		this.batchForm.controls['batchControl'].valueChanges.subscribe(value => {
			this.hasUniqueName = true;
			if ((typeof value === 'string')) {
				this.batchForm.controls['batchControl'].patchValue({ name: value }, { emitEvent: false });
			}
		});
		this.setDefaultBatch();
	}

	async updateModels(): Promise<any> {

		if (!this.isExistingGroup || !this.hasUniqueModelName) {
			const baseName = "New Model"
			const defaultModelName = await findUniqueName(baseName, this.isUniqueName.bind(this));
			this.modelForm?.controls['modelControl'].setValue({ name: defaultModelName });
		}

		this.modelForm.controls['modelControl'].valueChanges.subscribe(value => {
			this.hasUniqueName = true;
			if ((typeof value === 'string')) {
				this.modelForm.controls['modelControl'].patchValue({ name: value }, { emitEvent: false });
			}
		});
	}

	async setDefaultBatch() {

		if (isNil(this.imageGroupId)) {
			const baseName = "Uploaded On " + moment().format('M/D/Y');
			const defaultBatchName = await findUniqueName(baseName, this.isUniqueName.bind(this));
			this.batchForm.controls['batchControl'].setValue({ name: defaultBatchName });
		} else {
			const batch = this.batches?.find(byId(this.imageGroupId));
			if (batch?.id) {
				this.batchForm.controls['batchControl'].setValue(batch);
				this.setBatchSensor(batch);
			}
		}
	}

	setBatchSensor(batch) {
		this.multispectralImages = !!batch.sensor;
		this.multispectralSensor = batch.sensor ?? null;
	}

	filterValues(name: string, values: Array<any>): any {
		if (typeof name === "string") {
			let filterValue = name.toLowerCase().replace(" ", "");

			return values
				.filter((option) => {
					return (
						option.name
							.replace(" ", "")
							.toLowerCase()
							.includes(filterValue)
					);
				})
				.slice(0, 15)
				.sort(sortByNewestToOldest);
		} else {
			return null;
		}
	} // End-of filterEpsg

	ngAfterViewInit() {
		this.fileInput.nativeElement.value = ''; // Allow same file to be uploaded, in the event of similar names
	}	// End-of ngOnInit

	async createFormGroup() {

		this.batchForm = this._formBuilder.group({
			batchControl: ['', [Validators.required, Validators.maxLength(128), batchNameValidator]],
		});

		this.modelForm = this._formBuilder.group({
			modelControl: ['', [Validators.required, Validators.maxLength(128)]],
		});

	}	// End-of setupForms

	async getModelLayerPositions(model: Model): Promise<MinimapPosition[]> {
		const geotiffLayers = model.geotiffs;
		const geotiffPositions = geotiffLayers.map(layer => ({
			name: model.name,
			latitude: layer.north,
			longitude: layer.west
		}))
			.filter((layer) => {
				return !isNil(layer.latitude) && !isNil(layer.longitude);
			});

		if (geotiffPositions.length === 0) {
			const potreeLayers = model.potree_files;
			const potreePositions = await Promise.all(potreeLayers.map(async (layer) => {
				const resource = await this._modelService.getLayerResource(layer.url);

				if (resource.projection) {
					const projectionToScene = proj4(proj4.defs("WGS84"), resource.projection);
					const utmCoordinate = { x: resource.boundingBox.lx, y: resource.boundingBox.ly };
					const latLng = projectionToScene.inverse([utmCoordinate.x, utmCoordinate.y]);

					return {
						name: model.name,
						latitude: latLng[1],
						longitude: latLng[0]
					}
				} else {
					console.warn("Unable to obtain coordinates for minimap due to missing potree point cloud geo-reference.")
				}
			}))

			return potreePositions.filter((layer) => {
				return !isNil(layer?.latitude) && !isNil(layer?.longitude);
			});
		}
		return geotiffPositions;
	}

	isUniqueName(name: string) {
		return !this.batches?.length ||
			(this.batches?.length && isNil(
				this.batches.find(x =>
					x.name.toLowerCase() === name.toLowerCase()
				)
			));
	}

	dropzoneUpload(files): void {
		if (files.length) {
			if (flagLayer.isEnabled(availableFeatureFlags.folderUpload)) {
				this.filesChanged(files);
			} else {
				this.filesChanged(files);
			}
		} else {
			this.showDropzoneWarning = true;
		}
	}

	folderChanged(event): void {
		if (event.target?.files?.length) {
			const files = event.target.files;
			const fileName = files[0].webkitRelativePath;
			this.folderName = fileName?.match(/([\w ]+)\//)[1] ?? "";
			if (this.folderName) this.batchForm.patchValue({ name: this.folderName });
			this.filesChanged(files);
		}
	}

	filesChanged(input: FileList | File[]): void {

		this.showFiletypeError = false;

		// Ensure files are of the proper extension, if not, filter and alert
		const files = [...input];
		this.showFiletypeError = files.length !== input.length;

		if (files.length) {
			const uploadItems = convertFilesByType(files);
			this.uploadItems = [...this.uploadItems, ...uploadItems];
			this.uploadItemsByType = this.updateUploadItemsByType(this.uploadItems);
			const toShow = Object.entries(this.uploadItemsByType).reduce((acc, [key, uploadItems]) => {
				if (uploadItems.length) acc.push(key);
				return acc;
			}, []);
			this.checkImagesLocations(this.uploadItemsByType["Image"]);
			this.tabShowing = toShow[0] ?? "Image";

			this.checkVectorFiles(uploadItems);
		}

	}	// End-of filesChanged

	isTabType(type: UploadType) {
		return this.tabShowing === type;
	}

	setTabType(type: UploadType) {
		this.tabShowing = type;
	}

	clearFolderUpload() {
		this.uploadItems = [];
		this.folderName = "";
		this.createFormGroup();
		this.uploadItemsByType = defaultUploadItemsByType;
	}

	makeUploadItemsByType(uploadItems: UploadItem[] = this.uploadItems) {
		let newItems = uploadItems.reduce((acc, uploadItem) => {
			if (uploadItem.type === uploadTypeEnum.image) {
				acc["Image"].push(uploadItem);
			} else if (uploadItem.type === uploadTypeEnum.import) {
				acc["Map Layer"].push(uploadItem);
			} else {
				acc["File"].push(uploadItem);
			}
			return acc;
		}, { "Map Layer": [], "Image": [], "File": [] });
		if (newItems["Image"]?.length) {
			newItems["Image"] = newItems["Image"].sort((a, b) =>
				((!b.uploadInfo?.location?.latitude as any) - (!a.uploadInfo?.location?.latitude as any))
			);
		}
		return newItems;
	}

	updateUploadItemsByType(uploadItems: UploadItem[]) {
		const newItemsByType = this.makeUploadItemsByType(uploadItems);
		const oldItemsByType = cloneDeep(this.uploadItemsByType);
		return merge(oldItemsByType, newItemsByType);
	}

	setPlural(text, number): string {
		return pluralize(text, number);
	}

	preProcessImages = async (imageItems: UploadItem[]) => {
		let batch;
		batch = this.batchForm.value.batchControl;

		if (isNil(batch?.id)) {

			let batch_name = batch?.name ?? batch;
			const batch_name_regex = this.handleEdgeWhitespace(batch_name);

			if (batch_name !== batch_name_regex) {
				batch_name = batch_name_regex;

				this.batchForm.controls["batchControl"].patchValue({ name: batch_name });

				if (!this.isUniqueName(batch_name)) {
					this.hasUniqueName = false;
					return;
				}
			}
			batch = await this.createBatch(this.project, batch_name);
		}

		imageItems.forEach(uploadItem => Object.assign(uploadItem.uploadInfo, {
			batch,
		}));
		return batch;
	}

	handleEdgeWhitespace(text: string): string {
		const wsRegex = /^\s+|\s+$/g;
		return text.replace(wsRegex, '');
	}

	public createBatch(project: Project, batchTitle: string): Promise<ImageBatch> {
		return (this.multispectralImages ?
			this._imageService.createMultispectralBatch(batchTitle, project.organization_id, project.id, this.multispectralSensor) :
			this._imageService.createBatch(batchTitle, project.organization_id, project.id))
			.catch(console.error);
	}


	preProcessMapLayers = async (uploadItems: UploadItem[]) => {
		return await Promise.all(uploadItems.map(uploadItem => {
			if (this.hasVectorFiles && this.isVectorFile(uploadItem.file)) {
				uploadItem.altQuery = makeURLParams({type: this.selectedVectorOption.type});
			}

			if (this.isExistingModel) {
				Object.assign(uploadItem.uploadInfo, {
					model: this.selectedModelOption
				})
			} else {
			return this._modelService.createV2({ name: uploadItem.uploadInfo['name'], project_id: this.project.id })
				.then((rtnModel: Model) => {
					Object.assign(uploadItem.uploadInfo, {
						model: rtnModel
					})
				})
				.catch(err => {
					console.error(err);
					this._alertService.error(new Alert(`Failed to create model for ${uploadItem.file.name}`));
				})
			}
		}))
	}

	preProcessFiles = async (uploadItems) => {
		uploadItems.forEach(uploadItem => Object.assign(uploadItem, {
			type: uploadTypeEnum.file
		}));
	}

	preProcessAll = async (uploadItems) => {
		uploadItems.forEach((uploadItem: any) => {
			Object.assign(uploadItem.uploadInfo, {project: this.project, name: this.modelForm.controls['modelControl'].value.name})
		})
	}

	async submitUpload() {
		this.checkBatchNames();
		this.checkModelNames();

		if (this.uploadItems.length && (this.hasUniqueName || this.isExistingGroup)) {
			let batch;

			this.isLoading = true;
			await Promise.all(this.uploadItemsStrings.map(type => {
				if (this.uploadItemsByType[type]?.length) {
					this.preProcessAll(this.uploadItemsByType[type]);
				}
			}))

			if (this.uploadItemsByType[uploadTypeEnum.image]?.length) {
				batch = await this.preProcessImages(this.uploadItemsByType[uploadTypeEnum.image]);
			}
			if (this.uploadItemsByType[uploadTypeEnum.import]?.length) {
				await this.preProcessMapLayers(this.uploadItemsByType[uploadTypeEnum.import]);
			}
			if (this.uploadItemsByType[uploadTypeEnum.file]?.length) {
				await this.preProcessFiles(this.uploadItemsByType[uploadTypeEnum.file]);
			}

			this.uploadItemsStrings.forEach(type => {
				if (this.uploadItemsByType[type]?.length) {
					this.submitUploadByType(type, batch)
				}
			})
			this.close();
		}
	}

	checkBatchNames() {

		if (this.batchForm.value.batchControl?.name !== '' && this.batches?.length) {
			this.hasUniqueName = !this.batches.some((batch) => {
				return batch.name === this.batchForm.value.batchControl.name;
			});
		}

		if (this.batchForm.value.batchControl.id) {
			const checkBatch = this.batches.find((batch) => {
				return batch.id === this.batchForm.value.batchControl.id;
			})

			if (checkBatch?.id) {
				this.isExistingGroup = true;
				return;
			} else {
				this.isExistingGroup = false;
			}
		} else {
			this.isExistingGroup = false;
		}
	}

	checkModelNames() {
		if (this.modelForm.value.modelControl?.name !== '') {
			this.hasUniqueModelName = !this.modelOptions.some((model) => {
				return model.name === this.modelForm.value.modelControl.name;
			})
		}

		if (this.modelForm.value.modelControl.id) {

			const checkModel = this.modelOptions.find((model) => {
				return model?.id === this.modelForm.value.modelControl?.id;
			})

			if (checkModel?.id) {
				this.isExistingGroup = true;
				return;
			} else {
				this.isExistingModel = false;
			}
		} else {
			this.isExistingModel = false;
		}
	}

	submitUploadByType(type, batch?) {
		const uploadItems = this.uploadItemsByType[type];

		if (uploadItems?.length) {
			let upload = {
				files: uploadItems.map(x => x.file),
				uploadItems,
				project_id: this.project.id,
				org_id: this.project.organization_id,
				project: this.project,
				batch_id: batch?.id ?? null,
				type
			};

			return this._uploadService.__uploadFiles(upload);
		}
		return Promise.resolve();
	}

	isVectorFile(file) {
		const vectorFileList = ["kml", "kmz", "dxf"];
		const extension = extractFileExtension(file?.name).toLowerCase();
		return vectorFileList.includes(extension)
	}

	checkVectorFiles(uploadItems) {
		this.hasVectorFiles = uploadItems.some(item => {
			return this.isVectorFile(item.file);
		});
	}

	anyErrorsActive() {
		this.checkBatchNames();

		if (!this.uploadItemsByType[uploadTypeEnum.image]?.length) {
			return false;
		}

		const batchFormError = this.batchForm.controls['batchControl'].errors?.maxLength ||
			this.batchForm.controls['batchControl'].errors?.nameMissing ||
			this.batchForm.controls['batchControl'].errors?.required;

		return this.showFiletypeError ||
			(this.multispectralImages && !this.multispectralSensor.length) ||
			batchFormError;
	}

	close(): void {
		this._dialogRef.close(false);
	}

	openUploadInput(): void {
		this.fileInput?.nativeElement?.click();
	}

	removeItem(uploadItem: UploadItem): void {
		const itemIndex = this.uploadItems.findIndex(byId(uploadItem.id));
		this.uploadItems.splice(itemIndex, 1);
		const type = uploadItem.altType ?? uploadItem.type;
		const itemTypeIndex = this.uploadItemsByType[type].findIndex(byId(uploadItem.id));
		this.uploadItemsByType[type].splice(itemTypeIndex, 1);
		if (uploadItem.type === uploadTypeEnum.image) {
			this.checkImagesLocations(this.uploadItemsByType[uploadTypeEnum.image])
		}
		this.checkTabs();
	}

	checkImagesLocations(imageItems: UploadItem[]): void {

		this.minimapValues = [];
		let newMinimapValues = [];
		this.isLoading = true;

		const minimapItems = cloneDeep(imageItems).slice(0, 50);

		Promise.all(minimapItems.map(imageItem => {
			return this.checkImageLocation(imageItem, newMinimapValues);
		})).then((rtn) => {
			this.uploadItemsByType[uploadTypeEnum.image] = _.values(_.merge(_.keyBy(this.uploadItemsByType[uploadTypeEnum.image], 'id'), _.keyBy(rtn, 'id')))
				.sort((a, b) =>
					((!b.uploadInfo.location?.latitude as any) - (!a.uploadInfo.location?.latitude as any))
				);
			this.isLoading = false;
			this.minimapValues = newMinimapValues;
		}).catch(err => {
			console.error(err);
			this.isLoading = false;
		})
	}

	sortByValidity = (a: UploadItem, b: UploadItem) => (!isNil(a.uploadInfo.location?.longitude) as any) - (!isNil(b.uploadInfo.location) as any);

	fileTooBigToParse = ( imageItem: UploadItem ) => imageItem.file?.size > 24000000

	async checkImageLocation(imageItem: UploadItem, newMinimapValues: Array<any>): Promise<any> {
		if (this.fileTooBigToParse(imageItem)) { // minimum size guess ~24mb
			imageItem.uploadInfo.location = {};
			return imageItem;
		}
		if (!imageItem.uploadInfo.location && imageItem.file) {
			const tags = await ExifReader.load(imageItem.file, { expanded: true });

			if (!isNil(tags["gps"]?.Latitude) && !isNil(tags["gps"]?.Longitude)) {

				const gpsItem = {
					latitude: tags["gps"]?.Latitude,
					longitude: tags["gps"]?.Longitude
				};

				imageItem.uploadInfo.location = gpsItem;
				newMinimapValues.push(cloneDeep(gpsItem));
			} else {
				imageItem.uploadInfo.location = {};
			}
		} else {
			newMinimapValues.push(imageItem.uploadInfo.location);
		}
		return imageItem;
	}

	moveItemToFiles(uploadItem: UploadItem): void {
		const itemType = uploadItem.type;
		uploadItem.altType = uploadTypeEnum.file;
		const itemIndex = this.uploadItemsByType[itemType].findIndex(byId(uploadItem.id));
		const removedItem = this.uploadItemsByType[itemType].splice(itemIndex, 1);
		this.uploadItemsByType["File"].push(removedItem[0]);
		this.uploadItemsByType["File"] = this.uploadItemsByType["File"]
			.sort(this.sortByValidity);
		if (uploadItem.type === uploadTypeEnum.image) {
			this.checkImagesLocations(this.uploadItemsByType[uploadTypeEnum.image])
		}
		this.checkTabs();
	}

	moveItemBack(uploadItem: UploadItem): void {
		const itemType = uploadItem.type;
		uploadItem.altType = null;
		const itemIndex = this.uploadItemsByType["File"].findIndex(byId(uploadItem.id));
		if (itemIndex >= 0) {
			const removedItem = this.uploadItemsByType["File"].splice(itemIndex, 1)[0];
			this.uploadItemsByType[itemType].push(removedItem);
			this.uploadItemsByType[itemType] = this.uploadItemsByType[itemType]
				.sort(this.sortByValidity);
		} else {
			console.warn("Upload Item not in Files");
		}
		if (uploadItem.type === uploadTypeEnum.image) {
			this.checkImagesLocations(this.uploadItemsByType[uploadTypeEnum.image])
		}
		this.checkTabs();
	}

	formatSelectedBatch(val) {
		return val?.name ?? "";
	}

	formatSelectedModel(val) {
		return val?.name ?? "";
	}

	clearSelection(e, form) {
		e.preventDefault();
		e.stopPropagation();
		form === 'batch' ? this.batchForm.controls['batchControl']?.patchValue({ name: '' }) :
		this.modelForm.controls['modelControl']?.patchValue({ name: '' });
	}

	isSameTypeAsTab(uploadItem): boolean {
		return uploadItem.type === this.tabShowing;
	}

	checkTabs() {
		if (this.uploadItemsByType[this.tabShowing].length <= 0) {
			this.tabShowing = Object.keys(this.uploadItemsByType).find((key) => !!this.uploadItemsByType[key].length) as UploadType;
		}
	}

	moveBackTooltip(uploadItem: UploadItem): string {
		return `Move back to ${uploadItem.type ? uploadItem.type + 's' : "origin"}`;
	}

	isImageTab(tabShowing): boolean {
		return (tabShowing === uploadTypeEnum.image);
	}

	imageItemHasLocation(item: UploadItem): boolean {
		return isNil(item.uploadInfo.location) ||
			(!isNil(item.uploadInfo.location?.latitude) &&
				!isNil(item.uploadInfo.location?.longitude));
	}

	makeUploadItemsByTypeString(uploadItemsByType: UploadItemsByType, showLength: boolean = true): string {
		const uploadStrArr = Object.entries(uploadItemsByType).reduce((acc, [key, uploadItems]: [key: string, uploadItems: UploadItem[]]) => {
			if (uploadItems.length) {
				acc.push(`${showLength ? uploadItems.length : ''} ${this.setPlural(key, uploadItems.length)}`)
			}
			return acc;
		}, [])
		return uploadStrArr.length ? listFormatters.formatAnd(uploadStrArr) : "no groups"
	}

	makeIdentifyString(uploadItemsByType: UploadItemsByType, length: number): string {
		const nTabs = Object.values(uploadItemsByType).filter(x => x.length).length;
		const nItems = uploadItemsByType[this.tabShowing].length;
		return `${nTabs <= 1 ? "identified" : "split"} ${nTabs <= 1 ? `${nItems === 1 ? "it as a(n)" : "them as"}` : "into"}`;
	}

	async toggleMultispectralImages() {
		this.multispectralImages = !this.multispectralImages;
	}

	getExistingGroupText(batch) {
		return batch?.sensor ? "Existing Multispectral Group" : "Existing Group";
	}

	getExistingModelText(model) {
		return this.isExistingModel ? "Existing Model" : "New Model";
	}

	onSelectChange(model = null) {
		if (model) {
			this.selectedModelOption = model;
		}
			setTimeout(() => {
				if (this.batchForm.value.batchControl) {
					this.setBatchSensor(this.batchForm.value.batchControl);
				}
			}, 50)
	}

	protected readonly pluralize = pluralize;
}	// End-of class TestComponent
