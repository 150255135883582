/* Imports */
import { Injectable } from '@angular/core';
import _ from "lodash";

/* Services */
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { FinanceService } from './finance.service';
import { SessionService } from './session.service';
import { HttpService } from './http.service';
import { byId } from '../../shared/services/utils.service';
import { Observable, BehaviorSubject } from 'rxjs';

/* Models */
import { Organization, USERROLES, User, OrgIdsChangesModel } from '../models';
import { flagLayer, availableFeatureFlags } from "../featureFlags";


@Injectable()
export class OrganizationService {

	private static readonly ERR_ORG_INVALID = 'Organization information not valid';
	private orgChange: BehaviorSubject<any>;
	private activeOrgStateChange: BehaviorSubject<any> = new BehaviorSubject<any>({organization: {}, preventReload: false});
	private orgListChange: BehaviorSubject<any> = new BehaviorSubject<any>(
		{
			orgList: [],
			orgListIdsChanges: {
				addedOrgIds: [],
				removedOrgIds: []
			}
		}
	);

	private trialDaysRemaining: BehaviorSubject<number>;
	public _activeOrg: null | Organization = null;
	private _orgIds: number[] = [];
	public userOrgList: Array<any> = null;
	public isMobile: boolean = false;
	public isDeveloper: boolean = false;

	constructor(
		private httpService: HttpService,
		private _adminService: AdminService,
		private _authService: AuthenticationService,
		private _financeService: FinanceService,
	) {
		this.orgChange = new BehaviorSubject<any>(null);
		this.trialDaysRemaining = new BehaviorSubject<number>(null);

		this._adminService.getOverrideChanged().subscribe(rtn => {
			if (!isNaN(rtn?.trial)) {
				this.setTrialDays(rtn.trial);
			}
		});

		this._authService.orgObservable.subscribe(rtn => {
			if (rtn) {this.checkList();}
		})

		this._orgIds = SessionService.get("orgIds");

	}	// End-of constructor

	/**
	* [GETBYID]
	*
	* @param {number} orgId		ID of organization
	*/
	public getById(orgId: number): Promise<any> {

		let url = '/organizations/' + orgId;
		return this.httpService.get(url);

	}	// End-of getById

	public create(org: Organization): Promise<any> {

		let url = '/organizations';
		return this.httpService.post(url, org);

	}	// End-of create

	public update(org: Organization): Promise<any> {

		let url = '/organizations/' + org.id;
		return this.httpService.put(url, org);

	}	// End-of update

	public remove(org: Organization | number): Promise<any> {

		let orgID = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgID}`;
		return this.httpService.delete(url);

	}	// End-of removeOrganization

	public getUserList(org: Organization | number): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/users`;

		return this.httpService.get(url);

	}	// End-of getUserList

	/**
	 * [ADDSUBSCRIPTION]
	 *
	 * @param {User} user			User data
	 * @param {string} plan_id
	 * @param {string} payment_source_id
	 */
	public addSubscription(user: User, plan_id: string, payment_source_id: string): Promise<any> {
		let url = '/organizations/subscription';
		return this.httpService.post(url, { ...user, plan_id, payment_source_id });

	}	// End-of create

	/**
	 * [CHANGESUBSCRIPTION]
	 * CHANGE CHARGEBEE SUBSRCIPTION
	 * @param {plan_id: ''} plan
	 */
	 public changeSubscription(org: Organization | number, plan:any): Promise<any> {
		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/subscriptions`
		return this.httpService.put(url, plan);
	}	// End-of renew

	/**
	 * [RENEWSUBSCRIPTION]
	 * CHARGEBEE RENEW SUBSCRIPTION
	 * @param {Organization | number} org	Org | ID of organization
	 */
	 public renewSubscription(org: Organization | number): Promise<any> {
		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/renew`;
		return this.httpService.put(url, {});
	}	// End-of renew

	/**
	* [CANCELSUBSCRIPTION]
	* CHARGEBEE Cancel Plan
	* @param {Organization | number} org	Org | ID of organization
	*/
	public cancelSubscription(org: Organization | number): Promise<any> {
		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/subscriptions`;
		return this.httpService.delete(url);
	}	// End-of cancelSubscription

	/**
	* [UPDATEUSER]
	*
	* @param {Organization | number} org	ID of organization
	* @param {User: number} user			ID of user
	* @param {USERROLES} role				Role to assign to user
	*/
	public updateUser(org: Organization | number, user: User | number, role?: USERROLES): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let userId = (typeof user === 'object') ? user["id"] : user;
		let userRole = (role ? role : USERROLES.user);

		let url = `/organizations/${orgId}/users/${userId}`;
		let body = { role: userRole };
		return this.httpService.put(url, body);

	}	// End-of updateUser

	public removeUser(org: Organization | number, user: User | number): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let userId = (typeof user === 'object') ? user["id"] : user;

		let url = `/organizations/${orgId}/users/${userId}`;
		return this.httpService.delete(url);

	}	// End-of removeUser

	/**
	* [CREATEUSER]
	*
	* @param {Organization | number} org	ID of organization
	* @param {User: number} user			ID of user
	* @param {USERROLES} role				Role to assign to user
	*/
	public createUser(org: Organization | number, user: User | string, role?: USERROLES): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let userEmail = (typeof user === 'object') ? user["email"] : user;
		let userRole = (role ? role : USERROLES.user);

		let url = `/organizations/${orgId}/users`;
		let body = { email: userEmail, role: userRole };
		return this.httpService.post(url, body);

	}	// End-of createUser

	public getInvitationList(org: Organization | number): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/invitations`;

		return this.httpService.get(url);

	}	// End-of getInvigationList

	public removeInvitation(org: Organization | number, invitationId: number): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/invitations/${invitationId}`;

		return this.httpService.delete(url);

	}	// End-of removeUser

	public getProcessingCreditEvents(org: Organization | number): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/processing_credit_events`;

		return this.httpService.get(url);

	}	// End-of getProcessingCreditEvents


	public getOrgPlan = (org: Organization): Promise<any> => {
		return (flagLayer.isEnabled(availableFeatureFlags.newSubscription) ?
			this._financeService.getChargeBeePlans() :
			this._financeService.getPlans())
		.catch(console.error);
	}


	public getOrgStorage = (org: Organization = new Organization()): any => {
		if (org.subscription) {
			org.storage = this._adminService.getOverride('storage') ?? org.storage;
			org.used_storage = org.size ? this.bytesToGigabytes(org.size) : 0;
		} else {
			// TODO: THIS IS A TEMPORARY FIX
			// Presently, we do not have a plan for free accounts, these values are manually assigned here
			// When we add the plan, remove this check.
			org.storage = 12;
			org.used_storage = 0;
		}
		org.remaining_storage = org.storage - org.used_storage;
		return org;
	}

	public bytesToGigabytes(value: number): number {
		const outVal = Math.round(value / 1000000000);
		return outVal ? outVal : 0;
	}

	public deleteInvitation(org: Organization | number, invitation_id: number): Promise<any> {

		let orgId = (typeof org === 'object') ? org["id"] : org;
		let url = `/organizations/${orgId}/invitations/${invitation_id}`;

		return this.httpService.delete(url);

	}	// End-of deleteInvitation

	public checkRole(org: Organization | number): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {
			let orgId = (typeof org === 'object') ? org["id"] : org;

			this.getList().then(orgs => {
				let role = orgs.find(d => {
					return d.id == orgId;
				});

				role ? resolve(role) : reject("Org not listed");
			}, error => {
				reject(error);
			});
		});

	}	// End-of checkRole

	checkList(): void {
		this.getList().then(rtnList => {

			this.httpService.getResponseHeader('/organizations', 'trial-days-remaining').then(rtn => {
				if (!isNaN(parseInt(rtn))) {
					this.setTrialDays(parseInt(rtn));
				} else {
					this.setTrialDays(null);
				}
			});

			// Deep clone to avoid conflicts where userOrgList is updated, but old_org_list (being a copy of it) copies the new list instead of the old.
			let old_org_list = JSON.parse(JSON.stringify(this.userOrgList));
			this.userOrgList = rtnList;

			// Prevents accidental trigger on the event there are no orgs (on login)
			const recentLogin = SessionService.get('recentLogin');

			if (old_org_list.length > 0 && !recentLogin) {

				if (rtnList.length > old_org_list.length) {
					let added_org = rtnList.filter(x => !old_org_list.find(y => y.id === x.id));
					this.setOrgChange(added_org[0], 'added');
				} else if (rtnList.length < old_org_list.length) {
					let removed_org = old_org_list.filter(x => !rtnList.find(y => y.id === x.id));
					this.setOrgChange(removed_org[0], 'removed');
				}
			} else {
				SessionService.set('recentLogin', false);
			}
		});
	}

	getOrgChange(): Observable<any> {
		return this.orgChange.asObservable();
	}

	setOrgChange(org: Organization, type: 'added' | 'removed'): void {
		this.orgChange.next({ org: org, type: type });
	}

	// Active org is the org selected in `team-select.component`
	getActiveOrg(): Observable<any> {
		return this.activeOrgStateChange.asObservable();
	}

	setActiveOrg(organization: Organization, preventReload = false): void {
		if (organization?.id && this.activeOrgHasChanged(organization)) {
			this._activeOrg = organization;
			this.activeOrgStateChange.next( { organization, preventReload });
		}
	}

	clearActiveOrg(preventReload = true): void {
		this._activeOrg = null;
		this.activeOrgStateChange.next( { organization: null, preventReload });
	}

	activeOrgHasChanged(organization: Organization): boolean {
		return !_.isEqual(this._activeOrg, organization);
	}

	getOrgListChange(): Observable<any> {
		return this.orgListChange.asObservable();
	}

	setOrgListChange(orgList: Array<Organization>): void {
		if (this.orgListHasChanged(orgList)) {
			const orgListIdsChanges = this.getOrgListIdsChanges(orgList, this._orgIds);
			this._orgIds = orgList.map(x => x.id);
			SessionService.set("orgIds", this._orgIds);
			this.orgListChange.next( { orgList, orgListIdsChanges } );
		}
	}

	getOrgListIdsChanges = (newOrgList: Array<Organization>, oldOrgIds: Array<number>): OrgIdsChangesModel => {

		const addedOrgIds = newOrgList.reduce((acc, org) => {
			if (!oldOrgIds?.includes(org.id)) {
				acc.push(org.id);
			}
			return acc;
		}, []); // Compares lists, returns array of different items (no items = unchanged)

		const removedOrgIds = oldOrgIds?.filter(id => !newOrgList.find(x => x.id === id)) ?? [];
		return ({ addedOrgIds, removedOrgIds });
	}


	orgListHasChanged = (orgList: Array<Organization>): boolean => {
		const { addedOrgIds, removedOrgIds } = this.getOrgListIdsChanges(orgList, this._orgIds);
		return (!!addedOrgIds.length || !!removedOrgIds.length);
	}

	clearOrgChange(): void {
		this.orgChange = new BehaviorSubject<any>(null);
		this.orgListChange = new BehaviorSubject<any>({orgList: [], orgListIdsChanges: []});
	}

	getTrialDays(): Observable<any> {
		return this._adminService.getOverride('trial') ?? this.trialDaysRemaining.asObservable();
	}

	setTrialDays(trialDays: number): void {
		this.trialDaysRemaining.next(trialDays);
	}

	getOrgFromList(orgId: number): Organization | undefined {
		return this.userOrgList?.find(byId(orgId));
	}

	get(id: number): Promise<any> {
		const url = `/organizations/${id}`;
		return this.httpService.get(url);
	}	// End-of get

	getList(index?: number): Promise<any> {
		let url = `/organizations`;
		if (index) { url += `?start_at=${index}`; }
		return this.httpService.get(url).then( orgList => {
			orgList = orgList.filter(x => !_.isNil(x.id));
			this.userOrgList = orgList;
			this.setOrgListChange(orgList)
			return orgList;
		} );
	}	// End-of getList

	getListSearch(search: string, index?: number): Promise<any> {
		let url = `/organizations?search=${search}`;
		if (index) { url += `&start_at=${index}`; }
		return this.httpService.get(url);
	}	// End-of getListSearch

	getMemberships(organization_id: number): Promise<any> {
		let url = `/organizations/${organization_id}/users`;
		return this.httpService.get(url);
	}	// End-of getMemberships

}	// End-of OrganizationService

export const orgPredicate = {
	hasStorage: (org) => org.storage
}
