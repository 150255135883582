

import { Component, Input, OnInit } from "@angular/core";
import { FinanceService, OrganizationService, PermissionService } from "../../shared/services";
import { Organization, Plan } from "../../shared/models";
import { isEmpty } from "lodash";

interface Period {
	value: string;
	name: string;
	list: Array<any>;
}

const planText = {
	"active": "Active",
	"paused": "Paused",
	"cancelled": "Cancelled" ,
	"non_renewing": "Not Renewing"
};

@Component({
	selector: "app-new-plan",
	templateUrl: "./new-plan.component.html",
	styleUrls: ["./new-plan.component.scss"],
})
export class NewPlanComponent implements OnInit {

	@Input("orgList") organizationList: Array<any> = [];

	public selectedOrganization: Organization;
	public availablePlans: any = null;
	public productList: Plan[] = null;
	public selectedPlan: Plan = null;
	public userIsAdmin: boolean = false;
	public isLoading: boolean = true;

	public periodOptions: Period[] = [
		{value: "month", name: "Monthly", list: []},
		{value: "year", name: "Annually", list: []},
	];
	public selectedPeriod: Period = this.periodOptions[1];

	constructor(
		private _financeService: FinanceService,
		private _orgService: OrganizationService,
		private _permissionService: PermissionService
	) {
	}

	ngOnInit() {
		this.setup();
	}

	hasActiveOrgPlan = (org: Organization): boolean =>
		org.subscription?.status === "active";

	isOrgPlan = (plan: Plan, org: Organization): boolean =>
		this.hasActiveOrgPlan(org) && plan.id === org.subscription?.plan_id;

	async setup() {

		if (isEmpty(this.organizationList)) {
			this.organizationList = await this._orgService.getList();
		}

		if (this.organizationList.length >= 1) {
			if (this._orgService._activeOrg?.id) {
				this.selectedOrganization = this.organizationList.find(x => x.id === this._orgService._activeOrg.id);
			}
			if (!this.selectedOrganization?.id) {
				this.selectedOrganization =  this.organizationList[0];
			}
		}

		await this.checkUserIsAdmin(this.selectedOrganization);

		this.productList = await this._financeService.getProducts().then(rtnProducts =>
			rtnProducts.filter(prod => prod.metadata?.display));

		this.isLoading = false;
	}

	async updateActivePlans( plans: Plan[], org: Organization ): Promise<Plan[]> {
		if (this.hasActiveOrgPlan( org )) {
			// this.setSelectedPlanById(plans, org.subscription?.plan_id)
			// this.setSelectedPeriodByOrg(org);
		}
		return plans;
	}

	getPlanStatusClass = ( org: Organization ) =>
		"plan-status-" + org.subscription.status;

	setSelectedPlan = (plan: Plan) => this.selectedPlan = plan;

	isSelectedPlan = (plan: Plan) => this.selectedPlan === plan;

	async selectOrg(org: Organization) {
		this.isLoading = true;
		await this.checkUserIsAdmin(org);
		this.isLoading = false;
	}

	async checkUserIsAdmin(org: Organization) {
		this.userIsAdmin = await this._permissionService.compareToOrgPermissionsPromise(org, "admin");
	}

	getPlanStatusText = ( org: Organization ) =>
		planText[org.subscription?.status];

	selectPlan(plan: Plan) {
		this._financeService.createCheckout({
			line_items: [{ price: plan.default_price, quantity: 1 }],
			success_url: "https://app.mapware.com",
			cancel_url: "https://app.mapware.com",
			customer: this.selectedOrganization.stripe_id ?? null
		}).then(rtn => {
			if (rtn.url) {
				window.open(rtn.url, "_blank");
			}
		});
	}

	getPlanSelectText( plan: Plan, org: Organization ) {
		const MATCH_KEY = true as any;
		const matcher = {
			[MATCH_KEY]: "Change Plan",
			[this.isOrgPlan( plan, org ) as any]: "Current Plan",
			[!this.hasActiveOrgPlan( org ) as any]: "Select Plan",
		};
		return matcher[MATCH_KEY];
	}

	showPlanSelect = ( plan: Plan, org: Organization ): boolean =>
		!( this.isLoading || this.isOrgPlan( plan, org ) );
}
